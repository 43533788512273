import { ReactComponent as seti } from 'assets/images/sidemenu/setting.svg';

const icons = {
  seti: seti
};


const storedUserDetails = localStorage.getItem('rolepermission');

const logUserType = localStorage.getItem('usertype');

let userpermission = null;
let loginpermission = [];

if (storedUserDetails) {
  try {
    userpermission = JSON.parse(storedUserDetails);
    loginpermission = userpermission.permissions || [];
  } catch (error) {
    console.error('Failed to parse storedUserDetails:', error);
  }
}

const settings = {
  id: 'settings',
  title: 'Settings',
  type: 'group',
  children: [
    {
      id: 'Setting',
      title: 'Settings',
      type: 'collapse',
      icon: icons.seti,
      children: [
        {
          id: 'Applogo',
          title: 'App Logo',
          type: 'item',
          url: '/admin/settings/applogo'
        },
        {
          id: 'General',
          title: 'General',
          type: 'item',
          url: '/admin/settings/general'
        },
        {
          id: 'Email',
          title: 'Email',
          type: 'item',
          url: '/admin/settings/email'
        },
        {
          id: 'Payment',
          title: 'Payment',
          type: 'item',
          url: '/admin/settings/payment'
        },
        {
          id: 'SMS',
          title: 'SMS',
          type: 'item',
          url: '/admin/settings/sms'
        },
        {
          id: 'GoogleMapApi',
          title: 'API',
          type: 'item',
          url: '/admin/settings/googlemapapi'
        },
        {
          id: 'LCDWPaymentSettings',
          title: 'LCDW Payment Settings',
          type: 'item',
          url: '/admin/settings/lcdwpaymentsettings'
        },
        {
          id: 'EmailPreferences',
          title: 'Email Preferences',
          type: 'item',
          url: '/admin/settings/emailpreferences'
        },
        {
          id: 'FileFormatSize',
          title: 'File Format & Size',
          type: 'item',
          url: '/admin/settings/fileformatsize'
        },
        {
          id: 'EmailTemplate',
          title: 'Email Template',
          type: 'collapse',
          breadcrumbs: true,
          children: [
            {
              id: 'Header',
              title: 'Header',
              type: 'item',
              url: '/admin/settings/template/header',
            },
            // {
            //   id: 'CreateEmailTemplate',
            //   title: 'Create Email Template',
            //   type: 'item',
            //   url: '/admin/email-template/create-email',
            // },
            {
              id: 'ListEmailTemplate',
              title: 'List Email Template',
              type: 'item',
              url: '/admin/settings/template/list-email',
            },
            {
              id: 'Footer',
              title: 'Footer',
              type: 'item',
              url: '/admin/settings/template/footer',
            },
            // {
            //   id: 'Activity',
            //   title: 'Activity',
            //   type: 'item',
            //   url: '/admin/setting/email-template/activity',
            // }
          ]
        },
        {
          id: 'Currency',
          title: 'Currency',
          type: 'item',
          url: '/admin/settings/currency'
        },
        {
          id: 'LocationLimits',
          title: 'Location Limits',
          type: 'item',
          url: '/admin/settings/location-limits'
        },
        {  
           
          id: 'country-list',
          title: 'Country List',
          type: 'item',
          url: '/admin/settings/country/list',          
          
        },
        {             
           
        id: 'industry-list',
        title: 'Industry List',
        type: 'item',
        url: '/admin/settings/industry/list',          
          
        },
        // {
        //   id: 'DefaultLocation',
        //   title: 'Default Location',
        //   type: 'item',
        //   url: '/admin/setting/default-location'
        // }
      ]
    },
  ]
}


const modulesToCheck = ['Setting'];

if (logUserType !== 'super_admin') {

  if (settings && Array.isArray(settings.children) && loginpermission && Array.isArray(loginpermission)) {

    modulesToCheck.forEach(moduleId => {
      const module = settings.children.find(child => child.id === moduleId);

      if (module) {
        const modulePermission = loginpermission.find(permission => permission.module === moduleId);
        if (modulePermission) {
          if (modulePermission.create === 0 &&
              modulePermission.read === 0 &&
              modulePermission.update === 0 &&
              modulePermission.deletePermission === 0) {
              settings.children = settings.children.filter(child => child.id !== moduleId);
          }
        }
      }
    });
  }
  else
  {
    console.error('support.children or loginpermission is undefined or not an array');

  }
}

  if (settings.children.length === 0) {
    settings.title = '';
  }

export default settings;
