import { ReactComponent as reporti } from 'assets/images/sidemenu/reports.svg';

const icons = {
  reporti: reporti
};


const storedUserDetails = localStorage.getItem('rolepermission');

const logUserType = localStorage.getItem('usertype');

let userpermission = null;
let loginpermission = [];

if (storedUserDetails) {
  try {
    userpermission = JSON.parse(storedUserDetails);
    loginpermission = userpermission.permissions || [];
  } catch (error) {
    console.error('Failed to parse storedUserDetails:', error);
  }
}

const cms = {
  id: 'cms',
  title: 'CMS',
  type: 'group',
  children: [
    {
      id: 'Cms',
      title: 'CMS',
      type: 'collapse',
      icon: icons.reporti,
      children: [
        {
          id: 'View Page',
          title: 'View Page',
          type: 'item',
          url: '/admin/cms/list',
        },
        // {
        //   id: 'Add Page',
        //   title: 'Add Page',
        //   type: 'item',
        //   url: '/admin/cms/add',
        // },
        // {
        //   id: 'Add Page',
        //   title: 'Preview Page',
        //   type: 'item',
        //   url: '/admin/cms/preview',
        // },
      ]
    },


  ]
};


const modulesToCheck = ['Cms'];

if (logUserType !== 'super_admin') {
  
  if (cms && Array.isArray(cms.children) && loginpermission && Array.isArray(loginpermission)) {

    modulesToCheck.forEach(moduleId => {
      const module = cms.children.find(child => child.id === moduleId);

      if (module) {
        const modulePermission = loginpermission.find(permission => permission.module === moduleId);
        if (modulePermission) {
          if (modulePermission.create === 0 &&
              modulePermission.read === 0 &&
              modulePermission.update === 0 &&
              modulePermission.deletePermission === 0) {
              cms.children = cms.children.filter(child => child.id !== moduleId);
          }
        }
      }
    });
  } else {
    console.error('support.children or loginpermission is undefined or not an array');
  }
}

  if (cms.children.length === 0) {
    cms.title = '';
  }

export default cms;