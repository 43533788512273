import { useContext } from 'react';

// auth provider
import LcdwAuthContext from 'contexts/LcdwJWTProvider';
// import AuthContext from 'contexts/FirebaseContext';
// import AuthContext from 'contexts/AWSCognitoContext';
// import AuthContext from 'contexts/Auth0Context';

// ==============================|| HOOKS - AUTH ||============================== //

const useLcdwAuth = () => {
  const context = useContext(LcdwAuthContext);

 // if (!context) throw new Error('context must be use inside provider');

  return context;
};

export default useLcdwAuth;
