import { ReactComponent as SetiIcon } from 'assets/images/sidemenu/sms.svg';

const icons = {
  seti: SetiIcon
};



const storedUserDetails = localStorage.getItem('rolepermission');

const logUserType = localStorage.getItem('usertype');

let userpermission = null;
let loginpermission = [];

if (storedUserDetails) {
  try {
    userpermission = JSON.parse(storedUserDetails);
    loginpermission = userpermission.permissions || [];
  } catch (error) {
    console.error('Failed to parse storedUserDetails:', error);
  }
}

const Feedback = {
  id: 'feedback',
  title: 'Feedback',
  type: 'group',
  children: [
    {
      id: 'FeedbackList',
      title: 'Feedback List',
      type: 'collapse',
      icon: icons.seti,
      children: [
        {
          id: 'contactForm',
          title: 'Contact Form',
          type: 'item',
          url: '/admin/feedback/contact',
        },
        {
          id: 'locationFeedback',
          title: 'Location Feedback',
          type: 'item',
          url: '/admin/feedback/location'
        }
      ]
    },
  ]
};

const modulesToCheck = ['FeedbackList'];

if (logUserType !== 'super_admin') {

  if (Feedback && Array.isArray(Feedback.children) && loginpermission && Array.isArray(loginpermission)) {

    modulesToCheck.forEach(moduleId => {
      const module = Feedback.children.find(child => child.id === moduleId);

      if (module) {
        const modulePermission = loginpermission.find(permission => permission.module === moduleId);
        if (modulePermission) {
          if (modulePermission.create === 0 &&
              modulePermission.read === 0 &&
              modulePermission.update === 0 &&
              modulePermission.deletePermission === 0) {
              Feedback.children = Feedback.children.filter(child => child.id !== moduleId);
          }
        }
      }
    });
  }
  else
  {
    console.error('support.children or loginpermission is undefined or not an array');

  }
}

  if (Feedback.children.length === 0) {
    Feedback.title = '';
  }



export default Feedback;
