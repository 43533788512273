import { useMemo } from 'react';

// material-ui
import {  useMediaQuery } from '@mui/material';
// project-imports
import Search from './Search';
// import Message from './Message';
import Profile from './Profile';
// import Localization from './Localization';
import Notification from './Notification';
import MobileSection from './MobileSection';
//import MegaMenuSection from './MegaMenuSection';

import useConfig from 'hooks/useConfig';
import DrawerHeader from 'layout/MainLayout/Drawer/DrawerHeader';
import { MenuOrientation } from 'config';

//import l1 from 'assets/images/sidemenu/setting2.svg';

import ThemeMode from 'components/Customization/ThemeMode';
// import l2 from 'assets/images/sidemenu/sun.svg';


// ==============================|| HEADER - CONTENT ||============================== //

const HeaderContent = () => {

 // const theme = useTheme();

  const { mode, menuOrientation } = useConfig();

  const downLG = useMediaQuery((theme) => theme.breakpoints.down('lg'));

  const themeMode = useMemo(() => <ThemeMode />, [mode]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
 // const localization = useMemo(() => <Localization />, [i18n]);

//  const megaMenu = useMemo(() => <MegaMenuSection />, []);

  return (
    <>
      {menuOrientation === MenuOrientation.HORIZONTAL && !downLG && <DrawerHeader open={true} />}
      {!downLG && <Search />}
      {/* {!downLG && megaMenu} */}
      {/* {!downLG && localization}
      {downLG && <Box sx={{ width: '100%', ml: 1 }} />} */}
  {/* <img src={l2} alt="" /> */}
  {/* <img src={l1} alt="" /> */}

    {/* here my theme work */}
     
    {/* <Grid item xs={12}> */}
                    {themeMode}
                  {/* </Grid> */}

     

      <Notification />
      {/* <Message /> */}
    
      {!downLG && <Profile />}
      {downLG && <MobileSection />}
    </>
  );
};

export default HeaderContent;
