import { lazy } from 'react';
// project-imports
import CompanyGuard from 'utils/route-guard/CompanyGuard';
import CommonLayout from 'layout/CommonLayout';
import Loadable from 'components/Loadable';
// render - login
// const AuthLogin = Loadable(lazy(() => import('pages/auth/auth1/login')));
// const AuthRegister = Loadable(lazy(() => import('pages/auth/auth1/register')));
const AuthForgotPassword = Loadable(lazy(() => import('pages/auth/auth1/company-forget')));
const AuthCheckMail = Loadable(lazy(() => import('pages/auth/auth1/company-mail')));
const AuthResetPassword = Loadable(lazy(() => import('pages/auth/auth1/companyreset-password')));
// const AuthCodeVerification = Loadable(lazy(() => import('pages/auth/auth1/code-verification')));
//company login
const CompanyMobileVerifiy = Loadable(lazy(()=> import('pages/company/Auth/companymobileverify')));
const CompanyLogin = Loadable(lazy(() => import('pages/company/Auth/userlogin')));
const CompanyLoginRegister = Loadable(lazy(() => import('pages/company/Auth/companyuserregister')));
const CompanyEmailVerify = Loadable(lazy(() => import('pages/company/Auth/companyemailverify')));
const CompanySignWithOtp = Loadable(lazy(() => import('pages/company/Auth/companyverify')));
const AuthSignInOtp = Loadable(lazy(()=> import('pages/auth/auth1/CompanyMobileverify')));

const TemplateCms = Loadable(lazy(()=> import('pages/cms/template')));

// const CompanyRegister = Loadable(lazy(() => import('pages/company-user/registeration')));
// ==============================|| AUTH ROUTES ||============================== //
const LoginRoutes = {
  path: '/',
  children: [
    {
      path: '/',
      element: (
        <CompanyGuard>
          <CommonLayout />
        </CompanyGuard>
      ),
      children: [
        //company login  & company registeration
        {
          path: 'company',
          element: <CompanyLogin />
        },
        {
          path: 'company/:title',
          element: <TemplateCms/>
        },
        {
          path: 'company/register',
          element: <CompanyLoginRegister />
        },
        {
          path: 'company/email_verify/:id',
          element: <CompanyEmailVerify />
        },
        {
          path: 'company/mobile_verify/:id',
          element: <CompanyMobileVerifiy />
        },
        {
          path: 'company/login-with-otp',
          element: <CompanySignWithOtp />
        },
        {
          path: 'company/forgot-password',
          element: <AuthForgotPassword />
        },
        {
          path: 'company/check-mail',
          element: <AuthCheckMail />
        },
        {
          path: 'company/reset-password/:userId',
          element: <AuthResetPassword />
        },
        {
          path: 'company/send-mobile-otp',
          element: < AuthSignInOtp/>
        }
      ]
    }
  ]
};
export default LoginRoutes;