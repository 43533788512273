import React, { useMemo } from 'react';
import { Navigate } from 'react-router-dom';

const PermissionGuard = ({ children, moduleId }) => {

  // Retrieve stored user permissions from localStorage
  const storedUserDetails = localStorage.getItem('rolepermission');
  const userPermissions = storedUserDetails ? JSON.parse(storedUserDetails).permissions : [];

  const UserDetails = localStorage.getItem('user');
  const user = UserDetails ? JSON.parse(UserDetails) : null;
  const loginType = user ? user.login_type : null;

  // Memoize the module permission check
  const modulePermission = useMemo(() => {
    if (userPermissions && userPermissions.length > 0) {
      return userPermissions.find(permission => permission.module === moduleId);
    }
    return null;
  }, [userPermissions, moduleId]);

  // If login_type is 'company_sub_user', check permissions; otherwise, allow access
  if (loginType === 'company_sub_user') {
    if (!modulePermission || (
      modulePermission.create === 0 && 
      modulePermission.read === 0 && 
      modulePermission.update === 0 && 
      modulePermission.deletePermission === 0
    )) {
      // Redirect to a fallback page
      return <Navigate to="/maintenance/404_forbidden" />;
    }
  }

  // Render the children components if permission is granted or if loginType is not 'company_sub_user'
  return children;
};

export default PermissionGuard;
