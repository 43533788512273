import React, { useMemo } from 'react';
import { Navigate } from 'react-router-dom';

const PermissionGuard = ({ children, moduleId }) => {
  const logUserType = localStorage.getItem('usertype');

  // Ensure userPermissions is always an array
  const storedUserDetails = localStorage.getItem('rolepermission');
  let userPermissions = [];

  if (storedUserDetails) {
    try {
      userPermissions = JSON.parse(storedUserDetails).permissions || [];
    } catch (error) {
      console.error('Failed to parse storedUserDetails:', error);
    }
  }

  // Memoize the module permission check
  const modulePermission = useMemo(() => {
    if (userPermissions && Array.isArray(userPermissions)) {
      return userPermissions.find(permission => permission.module === moduleId);
    }
    return null;
  }, [userPermissions, moduleId]);

  // Bypass permissions check for super_admin
  if (logUserType === 'super_admin') {
    return children;
  }

  // If no permission or all permissions are denied, redirect
  if (!modulePermission || (
    modulePermission.create === 0 && 
    modulePermission.read === 0 && 
    modulePermission.update === 0 && 
    modulePermission.deletePermission === 0
  )) {
    return <Navigate to="/maintenance/404_forbidden" />;

  }

  // Render children components if permission is granted
  return children;
};

export default PermissionGuard;
