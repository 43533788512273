import { Link as RouterLink } from 'react-router-dom';

import { Link, Stack, Typography } from '@mui/material';
// import useCountries from 'hooks/useCountries';
import { useAppName } from 'contexts/AppNameContext';


// ==============================|| MAIN LAYOUT - FOOTER ||============================== //

const Footer = () => {

  const currentYear = new Date().getFullYear();
  // const [appName, setAppName] = useState('');
  const { appName } = useAppName();

  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ p: '24px 16px 0px', mt: 'auto' }}>
      <Typography variant="caption">
        &copy; {currentYear} {appName} . All Rights Reserved.
      </Typography>
      <Stack spacing={1.5} direction="row" justifyContent="space-between" alignItems="center">
        <Link
          component={RouterLink}
          to="/support"
          target="_blank"
          variant="caption"
          color="textPrimary"
          sx={{
            "&:hover": {
              color: "blue"
            }
          }}
        >
          Support
        </Link>
        <Link
          component={RouterLink}
          to="/terms_of_use"
          target="_blank"
          variant="caption"
          color="textPrimary"
          sx={{
            "&:hover": {
              color: "blue"
            }
          }}
        >
          Terms of Use
        </Link>
      </Stack>
    </Stack>
  );
  
};

export default Footer;
