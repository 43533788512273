import { Box, Stack, Link } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Link as RouterLink } from 'react-router-dom';
// import headerImage from 'assets/images/utilies.png'; 

function ContactHeader() {
  const theme = useTheme();

  return (
    <Box
      sx={{
        position: 'relative',
        overflow: 'hidden',
        pt: 1,
        pb: 2,
      }}
    >
      <Stack
        direction="row"
        justifyContent="space-between" // This aligns items on opposite sides
        alignItems="center" // Vertically aligns items in the center
        sx={{ px: 30 }} // Adds some padding to the sides
      >
        {/* Logo on the left */}
        <div className="logo">
          <img src={headerImage} alt="Logo" style={{ height: '70px' }} /> {/* Adjust height as needed */}
        </div>

        {/* Menu items on the right */}
        <Stack
          direction="row"
          spacing={3}
          sx={{
            '& .header-link': { fontWeight: 500, '&:hover': { color: theme.palette.primary.main } },
            display: { xs: 'none', md: 'flex' }, // Hide on small screens, show on medium and larger screens
          }}
        >
          <Link
            className="header-link"
            sx={{ ml: theme.direction === 'rtl' ? 3 : 0 }}
            color="secondary.main"
            component={RouterLink}
            to="/login"
            target="_blank"
            underline="none"
          >
            Dashboard
          </Link>
          <Link
            className="header-link"
            color="secondary.main"
            component={RouterLink}
            to="/components-overview/buttons"
            underline="none"
          >
            Components
          </Link>
          <Link
            className="header-link"
            color="secondary.main"
            href="#"
            target="_blank"
            underline="none"
          >
            Documentation
          </Link>
          <Link
            className="header-link"
            color="secondary.main"
            component={RouterLink}
            to="/Contact-us"
            underline="none"
          >
            Contact-us
          </Link>
        </Stack>
      </Stack>
    </Box>
  );
}

export default ContactHeader;
