import { lazy } from 'react';

// project-imports
import MainLayout from 'layout/LcdwMainLayout';
//import CommonLayout from 'layout/CommonLayout';
import Loadable from 'components/Loadable';
import LcdwAuthGuard from 'utils/route-guard/LcdwAuthGuard';


// render - sample page
const SamplePage = Loadable(lazy(() => import('pages/lcdw/dashboard')));
// const Demopage = Loadable(lazy(() => import('pages/lcdw/demopage')));
const SamplePage2 = Loadable(lazy(() => import('pages/extra-pages/sample-page2')));
const UserProfile = Loadable(lazy(() => import('pages/lcdw/userprofile')));
const RequestList = Loadable(lazy(() => import('pages/lcdw/requestlist')));
const BillingRequest = Loadable(lazy(() => import('pages/lcdw/billingpayment')));
const ChangePassword = Loadable(lazy(() => import('pages/lcdw/changepassword')));
const Activity = Loadable(lazy(() => import('pages/lcdw/action')));
const Logs = Loadable(lazy(() => import('pages/lcdw/logs')));


//location
const AddLocation = Loadable(lazy(() => import('pages/lcdw/lcdwLocationAdd')));
const EditLocation = Loadable(lazy(() => import('pages/lcdw/lcdwLocationEdit')));
const ViewLocation = Loadable(lazy(() => import('pages/lcdw/lcdwLocationView')));


//request list
const UtilitiesRequest = Loadable(lazy(() => import('pages/lcdw/LCDWRequest/UtilitiesPlanRequest')));
const LCDWRequest = Loadable(lazy(() => import('pages/lcdw/LCDWRequest/Requestmain')));
const AuthoritiesRequest = Loadable(lazy(() => import('pages/lcdw/LCDWRequest/AuthoritiesApproval')));
const Survey = Loadable(lazy(() => import('pages/lcdw/LCDWRequest/SurveyRequest')));
const GPRSurveys = Loadable(lazy(() => import('pages/lcdw/LCDWRequest/GPRSurveys')));

//new request list

const NewRequestList = Loadable(lazy(()=> import('pages/lcdw/LCDWRequest/NewRequestMain')));
// request add
const LCDWRequestAdd = Loadable(lazy(() => import('pages/lcdw/LCDWRequest/lcdwAdd')));
const UtilitiesRequestAdd = Loadable(lazy(() => import('pages/lcdw/LCDWRequest/utilityAdd')));
const AuthoritiesRequestAdd = Loadable(lazy(() => import('pages/lcdw/LCDWRequest/authoritiesAdd')));
const SurveyAdd = Loadable(lazy(() => import('pages/lcdw/LCDWRequest/surveyAdd')));
const GPRSurveysAdd = Loadable(lazy(() => import('pages/lcdw/LCDWRequest/gprsurveyAdd')));

//edit request
const LCDWRequestEdit = Loadable(lazy(() => import('pages/lcdw/LCDWRequest/EditData/lcdwEditData')));
const LCDWRequestDataAdd = Loadable(lazy(() => import('pages/lcdw/LCDWRequest/EditData/lcdwAddData')));

// LCDW USER
// const ListOfLCDWUsers=Loadable(lazy(() => import('pages/user/lcdwuser/view')));
// const ViewLCDWUserActivity=Loadable(lazy(() => import('pages/user/lcdwuser/viewlcdwuseractivity')));

//request view
const LCDWRequestView = Loadable(lazy(() => import('pages/lcdw/LCDWRequest/ViewRequest/lcdwView')));
const UtilitiesRequestView = Loadable(lazy(() => import('pages/lcdw/LCDWRequest/ViewRequest/utilityView')));
const AuthoritiesRequestView = Loadable(lazy(() => import('pages/lcdw/LCDWRequest/ViewRequest/authoritiesView')));
const SurveyView = Loadable(lazy(() => import('pages/lcdw/LCDWRequest/ViewRequest/surveyView')));
const GPRSurveysView = Loadable(lazy(() => import('pages/lcdw/LCDWRequest/ViewRequest/gprsurveysView')));
const ViewLocationRequest = Loadable(lazy(() => import(`pages/lcdw/LCDWRequest/viewData`)));

//Billing & payments
const WithdrawalList = Loadable(lazy(() => import('pages/lcdw/Billing & payments/withdrawallist')));
const BankList = Loadable(lazy(() => import('pages/lcdw/Billing & payments/banklist')));
const TemplateCms = Loadable(lazy(()=> import('pages/cms/template')));
const Logout = Loadable(lazy(()=> import('pages/lcdw/logout')));

const WithdrawalInvoice = Loadable(lazy(()=> import('pages/lcdw/Billing & payments/Paymentinvoice')));
import { WidgetStatistics, PaginationTable } from 'pages/extra-pages/lcdw_ViewLayout';

// ==============================|| MAIN ROUTES ||============================== //
const  ViewLayout = () => {
  return (
    <div>
      <WidgetStatistics />
      <PaginationTable />
    </div>
  );
};

const MainRoutes = {
  id: 'navigation',
  title: 'Navigation',
  // Breadcrumbs: true,
  type: 'group',
  path: '/',
  children: [
    {
      Breadcrumbs: false,
      path: 'logout',
      element: <Logout />
    },
    {
      Breadcrumbs: false,
      path: '/:title',
      element: <TemplateCms />
    },
    {
      Breadcrumbs: true,
      title: 'Home',
      path: '/',
      element: (
        <LcdwAuthGuard>
          <MainLayout />
        </LcdwAuthGuard>
      ),
      children: [
        {
          id: 'dashboard',
          title: 'Dashboard',
          type: 'item',
          path: 'lcdw/dashboard',
          element: <SamplePage />
        },
        {
          id: 'user-profile',
          title: 'User Profile',
          type: 'item',
          path: 'lcdw/user-profile',
          element: <UserProfile />
        },
        {
          path: 'lcdw/location/list',
          element: <ViewLayout />
        },
        {
          path: 'lcdw/location/photo-approval',
          element: <SamplePage2 />
        },
        {
          path: 'lcdw/location/add',
          element: <AddLocation /> // Use AddTableLayout for adding data with a table
        },
        {
          path: 'lcdw/location/edit/:id',
          element: <EditLocation />
        },
        {
          path: 'lcdw/location/view/:id',
          element: <ViewLocation />
        },
        {
          path: 'lcdw/request-list',
          element: <RequestList />
        },
        {
          path: 'lcdw/billing-payment',
          element: <BillingRequest />
        },
        {
          id: 'request-list',
          title: 'Request List',
          path: 'lcdw/request',
          Breadcrumbs: true,
          type: 'group',
          children: [
            {
              id: 'lcdw-new-request-list',
              title: 'New Request List',
              type: 'item',
              path: 'lcdw-new-request-list',
              element: <NewRequestList />
            },
            {
              id: 'request-list-view',
              title: 'View',
              type: 'item',
              path: 'lcdw-new-request-list/View/:id',
              element: <LCDWRequestView />
            },
            {
              id: 'view-data',
              title: 'View Data',
              type: 'item',
              path: 'view-data/:id',
              element: <ViewLocationRequest />
            },
            {
              id: 'utilities-plan-request',
              title: 'Utilities Plan Request',
              type: 'group',
              path: 'utilities-plan-request',
              Breadcrumbs: true,
              element: <UtilitiesRequest />,
              children: [
                {
                  id: 'utilities-add',
                  title: 'Utilities Add',
                  type: 'item',
                  path: 'utilities-add',
                  element: <UtilitiesRequestAdd />
                }
              ]
            },
            {
              id: 'lcdw-services-request',
              title: 'Lcdw Services Request',
              type: 'group',
              path: 'lcdw-services-request',
              Breadcrumbs: true,
              element: <LCDWRequest />,
              children: [
                {
                  id: 'lcdw-add',
                  title: 'Lcdw Add',
                  type: 'item',
                  path: 'lcdw-add',
                  element: <LCDWRequestAdd />
                },
                {
                  id: 'lcdw-add-data',
                  title: 'Lcdw Add Data',
                  type: 'item',
                  path: 'add-data/:firstId',
                  element: <LCDWRequestDataAdd />
                },
                {
                  id: 'lcdw-edit-data',
                  title: 'Lcdw Edit Data',
                  type: 'item',
                  path: 'edit-data/:firstId/:secondId',
                  element: <LCDWRequestEdit />
                }
              ]
            },
            {
              path: 'authorities-approval-request',
              element: <AuthoritiesRequest />
            },
            {
              path: 'authorities-approval-request/authorities-add',
              element: <AuthoritiesRequestAdd />
            },
            {
              path: 'survey-request',
              element: <Survey />
            },
            {
              path: 'survey-request/survey-add',
              element: <SurveyAdd />
            },
            {
              path: 'gpr-surveys-request',
              element: <GPRSurveys />
            },
            {
              path: 'gpr-surveys-request/gpr-surveys-add',
              element: <GPRSurveysAdd />
            },
            {
              id: 'utilities',
              title: 'View',
              type: 'item',
              path: 'utilities/view-request/:id',
              element: <UtilitiesRequestView />
            },
            {
              id: 'lcdw',
              title: 'View',
              type: 'item',
              path: 'lcdw/view-request/:id',
              element: <LCDWRequestView />
            },
            {
              id: 'authorities',
              title: 'View',
              type: 'item',
              path: 'authorities/view-request/:id',
              element: <AuthoritiesRequestView />
            },
            {
              id: 'survey',
              title: 'View',
              type: 'item',
              path: 'survey/view-request/:id',
              element: <SurveyView />
            },
            {
              id: 'gprsurveys',
              title: 'View',
              type: 'item',
              path: 'gprsurveys/view-request/:id',
              element: <GPRSurveysView />
            }
          ]
        },
        {
          id: 'Billing & Payments',
          title: 'Billing & Payments',
          type: 'group',
          path: 'billing-payments',
          Breadcrumbs: true,
          children: [
            {
              id: 'withdrawallist',
              title: 'Withdrawal Payments',
              type: 'item',
              path: 'lcdw/withdrawallist',
              element: <WithdrawalList />
            },
            {
              id: 'banklist',
              title: 'Bank List',
              type: 'item',
              path: 'lcdw/banklist',
              element: <BankList />
            },
          ]
        },
        {
          id: 'withdrawal-invoice',
          title: 'Withdrawal Invoice',
          type: 'item',
          path: 'billing-payments/lcdw/withdrawallist/withdrawal-invoice/:id',
          element: <WithdrawalInvoice />
        },
        {
          id: 'change-password',
          title: 'Change Password',
          type: 'item',
          path: 'lcdw/change-password',
          element: <ChangePassword />
        },
        {
          id: 'activity',
          title: 'Activity',
          type: 'item',
          path: 'lcdw/activity',
          element: <Activity />
        },
        {
          id: 'login-logs',
          title: 'Login Logs',
          type: 'item',
          path: 'lcdw/login-logs',
          element: <Logs />
        },
        // {
        //   id: 'demo-page',
        //   title: 'Demo Page',
        //   type: 'item',
        //   path: 'lcdw/demo-page',
        //   element: <Demopage />
        // },
      ]
    }
  ]
};
export default MainRoutes;
