import { ReactComponent as back } from 'assets/images/sidemenu/logout2.svg';

const icons = {
    // seti: seti,
    back: back
};


const logs = {
    id: '',
    title: '',
    type: 'group',
    children: [
        {
            id: 'logout',
            title: 'Logout',
            type: 'item',
            icon: icons.back,
            url: '/logout'
        }
    ]
};

export default logs;