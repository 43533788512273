// project-imports
import Hero from 'sections/landing/Header';
import Technologies from 'sections/landing/Technologies';
import Combo from 'sections/landing/Combo';
import Apps from 'sections/landing/Apps';
import Free from 'sections/landing/Free';
import Testimonial from 'sections/landing/Testimonial';
import Partner from 'sections/landing/Partner';
import ContactUs from 'sections/landing/ContactUs';
import Contact from 'sections/landing/Contact';
import Header from 'layout/CommonLayout/Header';
import Footer from 'layout/CommonLayout/FooterBlock';



// ==============================|| SAMPLE PAGE ||============================== //

const Landing = () => {
  return (
    <>
      <Header />
      <Hero />
      <Technologies />
      <Combo />
      <Apps />
      <Free />
      <Testimonial />
      <Partner />
      <ContactUs />
      <div id="contactSection">
      <Contact />
      </div>
      <Footer />
    </>
  );
};

export default Landing;
