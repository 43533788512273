// context/AppNameContext.js
import React, { createContext, useState, useContext, useEffect } from 'react';
import axios from 'axios';
import { APP_DEFAULT_PATH_BASE_URL } from '../config';

const AppNameContext = createContext();

export const useAppName = () => {
  return useContext(AppNameContext);
};

export const AppNameProvider = ({ children }) => {
  const [appName, setAppName] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchAppName = async () => {
      try {
        const appNameResponse = await axios.get(`${APP_DEFAULT_PATH_BASE_URL}api/get-siteappname-logo`);
        if (appNameResponse.data.success) {
          setAppName(appNameResponse.data.siteSetting.meta_value);
        } else {
          console.error('Failed to fetch app name:', appNameResponse.data.error);
        }
      } catch (err) {
        setError(err);
        console.error('Error fetching app name:', err);
      } finally {
        setLoading(false);
      }
    };

    // Fetch app name only if it's not already set
    if (!appName) {
      fetchAppName();
    }
  }, [appName]);

  return (
    <AppNameContext.Provider value={{ appName, loading, error }}>
      {children}
    </AppNameContext.Provider>
  );
};
