import { ReactComponent as seti } from 'assets/images/sidemenu/task-square.svg';

const icons = {
  seti: seti
};



const storedUserDetails = localStorage.getItem('rolepermission');

let userpermission = null;
let loginpermission = [];

if (storedUserDetails) {
  try {
    userpermission = JSON.parse(storedUserDetails);
    loginpermission = userpermission.permissions || [];
  } catch (error) {
    console.error('Failed to parse storedUserDetails:', error);
  }
}

const logs = {
  id: '',
  title: '',
  type: 'group',
  children: [

      {
        id: 'Logs',
        title: 'Logs',
        type: 'collapse',
        icon: icons.seti,
        children: [
          {
            id: 'login-logs',
            title: 'Login Logs',
            type: 'item',
            url: '/admin/logs/login-logs',
          },
            // {
            //   id: '',
            //   title: 'Login Logs',
            //   type: 'item',
            //   url: '/test',
            // },
            {
              id: 'location-logs',
              title: 'Location Logs',
              type: 'item',
              url: '/admin/logs/location-logs',
            },
            {
              id: '',
              title: 'Payment Logs',
              type: 'item',
              url: '/admin/logs/payment-logs',
            }
           
          ]
        },

 
  ]
};


  const modulesToCheck = ['Logs'];

if (logs && Array.isArray(logs.children) && loginpermission && Array.isArray(loginpermission)) {

  modulesToCheck.forEach(moduleId => {
    const module = logs.children.find(child => child.id === moduleId);

    if (module) {
      const modulePermission = loginpermission.find(permission => permission.module === moduleId);

      if (modulePermission) {
        if (modulePermission.create === 0 &&
            modulePermission.read === 0 &&
            modulePermission.update === 0 &&
            modulePermission.deletePermission === 0) {
            logs.children = logs.children.filter(child => child.id !== moduleId);
        }
      }
    }
  });
}
else{
  console.error('support.children or loginpermission is undefined or not an array');

}

  if (logs.children.length === 0) {
    logs.title = '';
  }

  export default logs;