import { lazy } from 'react';
// project-imports
import LcdwGuard from 'utils/route-guard/LcdwGuard';
import CommonLayout from 'layout/CommonLayout';
import Loadable from 'components/Loadable';
// render - login
// const AuthLogin = Loadable(lazy(() => import('pages/auth/auth1/login')));
// const AuthRegister = Loadable(lazy(() => import('pages/auth/auth1/register')));
const AuthForgotPassword = Loadable(lazy(() => import('pages/auth/auth1/Lcdw-forget')));
const AuthCheckMail = Loadable(lazy(() => import('pages/auth/auth1/Lcdw-mail')));
const AuthResetPassword = Loadable(lazy(() => import('pages/auth/auth1/lcdwreset-password')));
// const AuthCodeVerification = Loadable(lazy(() => import('pages/auth/auth1/code-verification')));
//company login
const LcdwLogin = Loadable(lazy(() => import('LcdwUser/LoginFiles/userlogin')));
const LcdwLoginRegister = Loadable(lazy(() => import('pages/lcdw/Auth/lcdwuserregister')));
const LcdwEmailVerify = Loadable(lazy(() => import('pages/lcdw/Auth/lcdwemailverify')));
const LcdwSignWithOtp = Loadable(lazy(() => import('pages/lcdw/Auth/lcdwotpverify')));

const TemplateCms = Loadable(lazy(()=> import('pages/cms/template')));

// const AuthSignInOtp = Loadable(lazy(()=> import('pages/auth/auth1/LcdwMobileVerify')));

// ==============================|| AUTH ROUTES ||============================== //
const LoginRoutes = {
  path: '/',
  children: [
    {
      path: '/',
      element: (
        <LcdwGuard>
          <CommonLayout />
        </LcdwGuard>
      ),
      children: [
        {
          path: 'lcdw',
          element: <LcdwLogin />
        },
        {
          path: 'lcdw/:title',
          element: <TemplateCms/>
        },
        {
          path: 'lcdw/register',
          element: <LcdwLoginRegister />
        },
        {
          path: 'lcdw/email_verify/:id',
          element: <LcdwEmailVerify />
        },
        {
          path: 'lcdw/login-with-otp',
          element: <LcdwSignWithOtp />
        },
        {
          path: 'lcdw/forgot-password',
          element: <AuthForgotPassword />
        },
        {
          path: 'lcdw/check-mail',
          element: <AuthCheckMail />
        },
        {
          path: 'lcdw/reset-password/:userId',
          element: <AuthResetPassword />
        },
        // {
        //   path: 'lcdw/send-mobile-otp',
        //   element: < AuthSignInOtp/>
        // }
      ]
    }
  ]
};
export default LoginRoutes;