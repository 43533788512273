import { useRoutes } from 'react-router-dom';
import LoginRoutes from './LoginRoutes';
import MainRoutes from './MainRoutes';
import CompanyLoginRoutes from './CompanyLoginRoutes';
import CompanyMainRoutes from './CompanyMainRoutes';
import LcdwLoginRoutes from './LcdwLoginRoutes';
import LcdwMainRoutes from './LcdwMainRoutes';
//import CompanyRoutes from './CompanyRoutes';
export default function ThemeRoutes({ loginType }) {
 // alert(loginType);
  sessionStorage.setItem('loginType', loginType);
  let routes;
  if (loginType === "admin") {
    routes = [LoginRoutes, MainRoutes];
  } else if (loginType === "company") {
    routes = [CompanyLoginRoutes, CompanyMainRoutes];
  } else if (loginType === "lcdw") {
    routes = [LcdwLoginRoutes, LcdwMainRoutes ];
  }
   else {
    // Handle other cases if needed
  }
  // Use useRoutes hook outside of conditional blocks
  const renderedRoutes = useRoutes(routes);
  return renderedRoutes;
}














