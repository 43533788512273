import React, { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { IconButton, Menu, MenuItem } from '@mui/material';
import { ThemeMode } from 'config';
import { Moon, Sun1,Setting2 } from 'iconsax-react';
import useConfig from 'hooks/useConfig';

const ThemeModeLayout = () => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);
  const {  onChangeMode } = useConfig();

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };  

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleModeChange = (selectedMode) => {
    onChangeMode(selectedMode);
    handleMenuClose();
  };

  return (
    <>
      <IconButton onClick={handleMenuOpen} sx={{ p: 0, borderRadius: '50%', bgcolor: 'transparent', '&:hover': { bgcolor: 'transparent' } }}>
          <Sun1 variant="Bold" color={theme.palette.warning.main} style={{ width: '27px', height: '27px' }} />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        PaperProps={{
          sx: {
            '& .MuiMenuItem-root': {
              padding: '8px 16px', // Adjust padding for menu items
            },
            '& .MuiListItemIcon-root': {
              minWidth: 'unset', // Remove minimum width for icons
              marginRight: '8px', // Add margin to icons
            },
            '& .MuiListItemText-primary': {
              fontSize: '14px', // Adjust font size for menu item text
            },
          },
        }}
      >
        <MenuItem onClick={() => handleModeChange(ThemeMode.LIGHT)}>
          <Sun1 variant="Bold" color={theme.palette.warning.main} style={{ width: '20px', height: '20px', marginRight: '8px' }} />
          Light
        </MenuItem>
        <MenuItem onClick={() => handleModeChange(ThemeMode.DARK)}>
          <Moon variant="Bold" style={{ width: '20px', height: '20px', marginRight: '8px' }} />
          Dark
        </MenuItem>
        <MenuItem onClick={() => handleModeChange(ThemeMode.DEFAULT)}>
          <Setting2 style={{ width: '20px', height: '20px', marginRight: '8px' }} />
          Default
        </MenuItem>
      </Menu>  
    </>
  );
};

export default ThemeModeLayout;
