import PropTypes from 'prop-types';
import { useEffect } from 'react';
import {  useNavigate } from 'react-router-dom';

// project-imports
import useAdminAuth from 'hooks/useCompanyAuth';

// ==============================|| AUTH GUARD ||============================== //

const CompanyAuthGuard = ({ children }) => {
  const { isLoggedIn } = useAdminAuth();
  const navigate = useNavigate();
  //const location = useLocation();


  useEffect(() => {
    if (!isLoggedIn) {
      setTimeout(() => navigate('/'), 100);
    }
  }, [isLoggedIn, navigate]);



  return children;
};

CompanyAuthGuard.propTypes = {
  children: PropTypes.node
};

export default CompanyAuthGuard;
