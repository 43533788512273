import { useContext } from 'react';

// auth provider
import CompanyAuthContext from 'contexts/CompanyJWTProvider';
// import AuthContext from 'contexts/FirebaseContext';
// import AuthContext from 'contexts/AWSCognitoContext';
// import AuthContext from 'contexts/Auth0Context';

// ==============================|| HOOKS - AUTH ||============================== //

const useCompanyAuth = () => {
  const context = useContext(CompanyAuthContext);

 // if (!context) throw new Error('context must be use inside provider');

  return context;
};

export default useCompanyAuth;
